import { TagItem } from '@/components/form';

export const SERVICE_ROLE_TYPES: string[] = ['Ongoing', 'Assessment'];

export const SERVICE_ROLE_TYPES_TAGS = (): TagItem[] =>
  SERVICE_ROLE_TYPES.map((v, id) => ({
    label: v,
    value: { id, value: v },
  }));

export const SERVICE_ROLES: string[] = ['In-Home', 'Online'];
