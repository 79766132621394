import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { forwardRef } from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm select-none font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary/90 text-primary-foreground shadow hover:bg-primary active:bg-blue-700',
        destructive: 'bg-destructive/90 text-destructive-foreground shadow-sm hover:bg-destructive active:bg-red-600',
        outlineDestructive: 'border border-destructive/90 bg-background text-destructive shadow-sm hover:bg-destructive/5',
        outline: 'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary/70 text-secondary-foreground shadow-sm hover:bg-secondary active:bg-blue-100/70',
        ghost: 'text-secondary-foreground hover:bg-secondary',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-12 px-4 py-2',
        sm: 'h-10 rounded-md px-3 text-xs',
        lg: 'h-11 rounded-md px-8',
        xl: 'h-14 px-4 py-2 text-lg font-semibold',
        icon: 'size-9',
        iconLg: 'size-11',
        iconSm: 'size-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
