import { TagItem } from '@/components/form';

export const LANGUAGES: string[] = [
  'English',
  'Spanish',
  'French',
  'Japanese',
  'Armenian',
  'Farsi',
  'Tagalog',
  'Russian',
  'Hebrew',
  'Hindi',
];

export const LANGUAGES_TAGS = (): TagItem[] =>
  LANGUAGES.map((v) => ({
    label: v,
    value: { id: v, value: v },
  }));
