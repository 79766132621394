import { CountryData } from '@/components/form';

export const DAYS_OF_WEEK = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
export const APPLE_STORE_LINK = 'https://apps.apple.com/ua/app/maxima-therapy-app/id6615087427?l=en';
export const GOOGLE_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.maxtherapy.app&hl=en';

export const DAYS_OF_MONTH = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
];

export const MONTHS = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

export const PHONE_COUNTRIES: CountryData[] =
  process.env.NEXT_PUBLIC_ENV !== 'production'
    ? [
        ['Ukraine', 'ua', '380', '(..) ... .. ..'],
        ['United States', 'us', '1', '(...) ...-....', 0],
      ]
    : [['United States', 'us', '1', '(...) ...-....', 0]];

export const SIGNATURE_START_TIMES: string[] = ['This month', 'Last month', 'This pay period', 'Last pay period'];

export const PROGRAM_NUMBERS: number[] = [805, 116];

export const REGIONAL_CENTERS: string[] = ['NLACRC', 'SGPRC', 'CVRC'];

export const RELATIONSHIPS: string[] = ['Mother', 'Father', 'Foster Parent', 'Other'];


