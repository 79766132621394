export enum ROLE_CHANGE_REASONS {
  // RESTORED_BY_ADMIN = 'restored-by-admin',
  // UPDATED_BY_ADMIN = 'updated-by-admin',
  // RESTORED_AFTER_TIME_LIMIT = 'restored-after-time-limit',
  // RESTORED_AFTER_REPORTS_FIXED = 'restored-after-reports-fixed',
  START_OF_PROBATION = 'start-of-probation-period',
  LIMITED_BY_ADMIN = 'limited-by-admin',
  LOW_PARTICIPATION = 'low-participation',
  POOR_SERVICE_QUALITY = 'poor-service-quality',
  PAST_DUE_REPORTS = 'past-due-reports',
  OVERTIME = 'overtime',
}
