import { TagItem } from '@/components/form';

export const SHIFT_STATUSES: string[] = ['Draft', 'Scheduled', 'Pending', 'Completed', 'No show', 'Canceled'];

export const SHIFT_START_TIMES: string[] = [
  'This week',
  'This month',
  'This pay period',
  'Previous week',
  'Previous month',
  'Previous pay period',
];

export const SHIFT_STATUSES_TAGS = (): TagItem[] =>
  SHIFT_STATUSES.map((v, id) => ({
    label: v,
    value: { id, value: v },
  }));
