import { FREQUENCY_TYPES } from "../enums";

export interface Frequency {
  label: string;
  count: number;
  type: FREQUENCY_TYPES;
}

export const FREQUENCY: string[] = [FREQUENCY_TYPES.WEEK, FREQUENCY_TYPES.MONTH, FREQUENCY_TYPES.ONE_TIME];
export const HOURS_FREQUENCY: number[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
  33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50
];
