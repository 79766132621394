export const STATES: string[] = [
  'On Hold',
  'Rejected',
  'Terminated',
  'Pending Approval',
  'Client Available',
  'Contacting Parents',
  'Therapist Assigned',
];

export enum STATES_TYPES {
  ON_HOLD = 'On Hold',
  REJECTED = 'Rejected',
  TERMINATED = 'Terminated',
  PENDING_APPROVAL = 'Pending Approval',
  CLIENT_AVAILABLE = 'Client Available',
  CONTACTING_PARENTS = 'Contacting Parents',
  THERAPIST_ASSIGNED = 'Therapist Assigned',
}
